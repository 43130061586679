import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Menu from "../components/smart_dom/Menu"
import Alarmy from "../components/smart_dom/Alarmy"
import Monitoring from "../components/smart_dom/Monitoring"
import Akcesoria from "../components/smart_dom/Akcesoria"
import Sterowanie from "../components/smart_dom/Sterowanie"
import Seo from "../components/Seo"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "smart_dom" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "smart_dom" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const SmartDom = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="smart_dom_top" className="page smartDomPage">
      <Seo title="Inteligentne domy" />
      <Top image={imgTop} />
      <Menu />
      <Alarmy />
      <Monitoring />
      <Akcesoria />
      <Sterowanie />
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
    </div>
  )
}

export default SmartDom
