import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Monitoring = () => {
  return (
    <div id="monitoring">
      <div className="smartDomElement">
        <StaticImage
          src="../../assets/images/smart_dom/monitoring.png"
          alt="monitoring"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>MONITORING</h3>
          <p>
            Systemy wizyjne pozwalają na większy nadzór nad budynkami. Zespół
            kamer na zewnątrz i wewnątrz obiektu, usługa podglądu online oraz
            archiwizacji nagrań to doskonałe rozwiązanie dla wszystkich, którzy
            cenią sobie bezpieczeństwo swojego mienia. Oferujemy stworzenie
            optymalnego systemu monitorowania, dostosowanego do potrzeb
            klientów.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Monitoring
