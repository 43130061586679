import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Alarmy = () => {
  return (
    <div id="alarmy">
      <div className="smartDomElement">
        <StaticImage
          src="../../assets/images/smart_dom/alarmy.png"
          alt="alarmy"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>ALARMY</h3>
          <p>
            Systemy alarmowe to doskonałe rozwiązanie dla wszystkich chcących
            chronić swoje mienie. Są powszechnie stosowane w domach, firmach i
            obiektach publicznych. Zapewniają poczucie bezpieczeństwa,
            sygnalizując próby włamania, akty wandalizmu czy pożar. W naszej
            ofercie znajdują się systemy alarmowe dostosowane do potrzeb i
            oczekiwań klientów. Nasi specjaliści pomagają zaprojektować i
            skonfigurować najlepsze rozwiązania – dopasowane do Ciebie.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Alarmy
