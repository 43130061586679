import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

import SideMenu from "../SideMenu"

import "../../assets/css/smart_dom.css"

const Menu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/smart_dom#alarmy"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/smart_dom/menu/alarmy.png"
            alt="alarmy"
            placeholder="tracedSVG"
          />
          <h3>alarmy</h3>
        </AnchorLink>
        <AnchorLink
          to="/smart_dom#monitoring"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/smart_dom/menu/monitoring.png"
            alt="monitring"
            placeholder="tracedSVG"
          />
          <h3>monitoring</h3>
        </AnchorLink>
        <AnchorLink
          to="/smart_dom#akcesoria"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/smart_dom/menu/akcesoria.png"
            alt="akcesoria"
            placeholder="tracedSVG"
          />
          <h3>akcesoria</h3>
        </AnchorLink>
        <AnchorLink
          to="/smart_dom#sterowanie"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/smart_dom/menu/sterowanie.png"
            alt="sterowanie"
            placeholder="tracedSVG"
          />
          <h3>sterowanie</h3>
        </AnchorLink>
      </div>
      <SideMenu base="/smart_dom#smart_dom_top">
        <div className="sideMenuElement">
          <h3>alarmy</h3>
          <AnchorLink to="/smart_dom#alarmy">
            <StaticImage
              src="../../assets/images/smart_dom/menu/alarmy.png"
              alt="alarmy"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>monitoring</h3>
          <AnchorLink to="/smart_dom#monitoring">
            <StaticImage
              src="../../assets/images/smart_dom/menu/monitoring.png"
              alt="monitring"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>akcesoria</h3>
          <AnchorLink to="/smart_dom#akcesoria">
            <StaticImage
              src="../../assets/images/smart_dom/menu/akcesoria.png"
              alt="akcesoria"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>sterowanie</h3>
          <AnchorLink to="/smart_dom#sterowanie">
            <StaticImage
              src="../../assets/images/smart_dom/menu/sterowanie.png"
              alt="sterowanie"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
      </SideMenu>
      <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
        <StaticImage
          src="../../assets/images/oze/oze_back.png"
          alt="sterowanie"
          placeholder="tracedSVG"
        />
      </Link>
    </>
  )
}

export default Menu
