import React from "react"

const Sterowanie = () => {
  return (
    <div className="sterowanieContainer">
      <div id="sterowanie">
        <div className="smartDomElement">
          <div className="title t1">STEROWANIE</div>
          <div className="text-holder">
            <p>
              Nasza firma zajmuje się montażem systemów „smart home”
              („inteligentny dom”) – pozwalających na sterowanie oświetleniem,
              ogrzewaniem, wentylacją, roletami czy bramami z aplikacji w
              telefonie. To wygodne rozwiązanie dla ceniących sobie komfort.
              Każda oferta jest przygotowywana indywidualnie, zapewniając
              najlepsze dopasowanie do potrzeb klientów.
            </p>
          </div>
          <div className="title t2">INSTALACJE ELEKTRYCZNE</div>
          <div className="text-holder2">
            <p>
              Wykonujemy instalacje elektryczne dla klientów indywidualnych i
              firmowych. Posiadamy doświadczenie i wyszkolonych specjalistów.
              Zapraszamy do kontaktu i poznania szczegółów naszej oferty!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sterowanie
