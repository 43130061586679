import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Akcesoria = () => {
  return (
    <div id="akcesoria">
      <div className="smartDomElement">
        <StaticImage
          src="../../assets/images/smart_dom/akcesoria.png"
          alt="akcesoria"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder">
          <h3>AKCESORIA</h3>
          <p>
            Proponujemy także akcesoria, ułatwiające użytkowanie systemów
            alarmowych – np. piloty sterujące alarmem, czujki, dodatkowe
            manipulatory. Zapraszamy do kontaktu i zapoznania się z naszymi
            propozycjami.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Akcesoria
